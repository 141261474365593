import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import '../layouts.scss';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';

export const ContentLayout: FC = () => (
  <>
    <Header />
    <Container className='layout-container_content'>
      <Outlet />
    </Container>
    <Footer />
  </>
);
