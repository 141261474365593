import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { getRoutes } from './routes';

function App() {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  const router = sentryCreateBrowserRouter(getRoutes());

  return <RouterProvider router={router} />;
}

export default App;
