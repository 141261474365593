import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { SecondaryButton } from '../Buttons';
import './footer.scss';

export const Footer: FC = () => {
  return (
    <footer className='footer'>
      <Container>
        <Row className='footer-content'>
          <Col md={8} className='text-center text-md-left'>
            <h2>What to find out more? Get in touch with us!</h2>
          </Col>
          <Col md={4} className='d-flex align-items-center justify-content-md-end justify-content-center'>
            <SecondaryButton label='Contact us' href='mailto:info@theballroomconnection.com' />
          </Col>
        </Row>
        <Row className='footer-links'>
          <Col md={8}>
            <Nav className='flex-column'>
              <NavLink className='nav-link' to='/terms-and-conditions'>
                Terms and conditions
              </NavLink>
              <NavLink className='nav-link' to='/cookies'>
                Cookies
              </NavLink>
              <NavLink className='nav-link' to='/privacy-policy'>
                Privacy policy
              </NavLink>
            </Nav>
          </Col>
          <Col md={4} className='footer-copy d-flex align-items-center justify-content-center justify-content-md-end'>
            <p>&copy; 2023 The Ballroom Connection limited.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
