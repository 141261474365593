import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import '../layouts.scss';
import { LandingHeader } from '../../components/LandingHeader';
import { Footer } from '../../components/Footer';

export const StartLayout: FC = () => (
  <>
    <LandingHeader />
    <Container className='layout-container_start'>
      <Outlet />
    </Container>
    <Footer />
  </>
);
