import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './Start.scss';

export const Start: FC = () => {
  return (
    <Container>
      <Row>
        <Col md={12} className='d-flex flex-column align-items-center justify-content-center text-center'>
          <p>An exciting new service for competitive Ballroom and Latin dancers is launching soon!</p>
          <p>
            To join our mailing list, email{' '}
            <a href='mailto:info@theballroomconnection.com'>info@theballroomconnection.com</a>.
          </p>
        </Col>
      </Row>
    </Container>
  );
};
