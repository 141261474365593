import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';

export const NotFoundPage: FC = () => (
  <>
    <Header />
    <Container className='layout-container_content'>
      <h1>Page Not Found</h1>
      <p>The page you are looking for might have been removed or is temporarily unavailable.</p>
    </Container>
    <Footer />
  </>
);
