import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './LandingHeader.scss';
import { Header } from '../Header';
import { Logo } from '..//Logo';

type LandingHeaderProps = {
  className?: string;
};

export const LandingHeader: FC<LandingHeaderProps> = () => {
  const blockName = 'landing-header';

  return (
    <header>
      <Header />
      <Container className={`${blockName}_content`}>
        <Row>
          <Col md={12} className='d-flex align-items-center justify-content-center'>
            <Logo />
          </Col>
        </Row>
      </Container>
    </header>
  );
};
