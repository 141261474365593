import React from 'react';
import { useRouteError } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Alert } from 'react-bootstrap';

export function RouterErrorBoundary() {
  const error = useRouteError();
  Sentry.captureException(error);
  return (
    <div className='container'>
      <Alert variant='danger'>
        <h1>Something has gone wrong</h1>
      </Alert>
      <p>There is an error with this page. Please try again later.</p>
    </div>
  );
}
